<template>
    <div class="category-card">
        <div class="category-card-image">
            <div v-if="icon!==null" :alt="label" class="category-card-img__icon" v-html="icon"></div>
        </div>
        <div class="category-card-content">
            <h5 class="category-card-content__title">{{ label }}</h5>
            <ul class="category-card-content__list">
                <li v-for="(option, index) in options" :key="index"><router-link :to="getLink(option.label)">{{ option.label }}</router-link></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { getCategoryIcon } from '@/utils/lupe-helpers';

    export default {
        name: 'ExtendedKategorieCard',
        props: {
            item: {
                type: Object,
                required: true
            },
            mainCategory: {
                type: Object,
                required: true
            },
            subCategoryId: {
                type: Number,
                required: true
            },
            isLtr: {
                type: Boolean,
                default: true,
            }
        },
        computed: {
            label(){
                return this.item.label;
            },
            subCategory(){
                if(this.item!==null){
                    return {
                        id: this.subCategoryId,
                        value : this.item.label
                    }
                }
                return null;
            },
            icon() {
                return getCategoryIcon(this.label);
            },
            options() {
                return this.item.options;
            },
        },
        methods: {
            getLink(label){
                const args = new Map();
                args.set(this.mainCategory.id,[this.mainCategory.value]);
                args.set(this.subCategoryId,[this.subCategory.value]);
                args.set(this.item.field_type_id,[label]);
                return "/angebote?filters="+encodeURIComponent(JSON.stringify(Array.from(args.entries())));
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .category-card {
        display: flex;
        flex-direction: row;
        border: none;
        padding: 15px;
        min-height: 142px;
        align-items: center;
        margin-bottom: 15px;

        &-image {
            margin-left: 5px;
            margin-right: 25px;
        }

        &-img__icon{
            width: 50px;
            fill: $primary;            
        }

        &-content {
            align-self: flex-start;
            &__title {
                text-transform: uppercase;
                color: $black-color;
                margin-bottom: 10px;
            }

            &__list {
                li {
                    font-size: 13px;
                    line-height: 18px;
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    body[dir="rtl"] {
        .category-card {
            
            &-image {
                margin-left: 25px;
                margin-right: 5px;
            }
        }
    }
</style>
